import { Field, Form, Formik } from "formik";
import { Component } from "react";
import personService from "../../../services/api/person.service";
import Swal from "sweetalert2";
import eventBus from "../../../common/EventBus";

type Props = {
    note: string,
    personId: number
    updateCallback: (note: string) => Promise<void>
};

export default class NoteComponent extends Component<Props> {
    render() {
        return <div className="p-2">
            <div className="d-flex justify-content-between align-items-center">
                <h3 className="m-0 p-2">Note</h3>
            </div>
            <Formik
                initialValues={{note: this.props.note}}
                onSubmit={async (values, formik) => {
                    eventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

                    await personService.updateEmployeeNote(
                        this.props.personId,
                        values.note
                    ).then(async (response) => {
                        await this.props.updateCallback(response.note);
                        Swal.fire(
                            "Salvato con successo",
                            undefined,
                            "success"
                        );
                    }).catch((error) => {
                        Swal.fire(
                            "Salvataggio non riuscito",
                            "Si è verificato un errore impprevisto durante il salvataggio.",
                            "error"
                        );
                    }).finally(() => {
                        eventBus.dispatch("hideLoader");
                    })
                }}
            >
                <Form className="p-2">
                    <Field
                        id="note"
                        name="note"
                        as="textarea"
                        className="w-100"
                        rows={12}
                        default={this.props.note}
                    />
                    <br /><br />
                    <div className="w-100 d-flex justify-content-end p-2 mb-3">
                        <button className="btn btn-outline-primary px-4 fw-bold" type="submit">Salva</button>
                    </div>
                </Form>
            </Formik>
        </div>
    }
}